import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const CircularLoading: React.FC = () => {

    return (<Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh' // full viewport height
      }}><CircularProgress color="primary"/></Box>);

}

export default CircularLoading;
