import React, { useState } from "react";
import "./terminosservicio.css";
import imgTerServ from "../../assets/images/terminosServicioLogo.png";
// import { termsOptions } from "./termsTexts";
import { useTranslation } from "react-i18next";

const TerminosServicioPage: React.FC = () => {
  const {t} = useTranslation()
  interface TabAccordeon {
    title: string;
    description: string;
    id: number;
    active: boolean;
  }

  function handleOpenCloseTab(item: TabAccordeon) {
    if (item.active) {
      setAccordeonTerminosServicios(
        accordeonTerminosServicios.map((el) =>
          el.id === item.id ? { ...el, active: false } : el
        )
      );
    } else {
      setAccordeonTerminosServicios(
        accordeonTerminosServicios.map((el) =>
          el.id === item.id ? { ...el, active: true } : el
        )
      );
    }
  }

  const termsOptions = [
    {
      title: t('terms.accordion_1.title'),
      description: `<p>${t('terms.accordion_1.p_1')}</p>`,
      active: false,
      id: 0,
    },
    {
      title: t('terms.accordion_2.title'),
      description: `<p><strong>${t('terms.accordion_2.p_1')}</strong></p>
          <p>${t('terms.accordion_2.p_2')}</p>
          <p>${t('terms.accordion_2.p_3')}</p>
          <p>${t('terms.accordion_2.p_4')}</p>`,
      active: false,
      id: 1,
    },
    {
      title: t('terms.accordion_3.title'),
      description: `<p><strong>${t('terms.accordion_3.title')}:</strong> ${t('terms.accordion_3.p_1')}</p>
          <p>${t('terms.accordion_3.p_2')}</p>
          <p>${t('terms.accordion_3.p_3')}</p>
          <p>${t('terms.accordion_3.p_4')}</p>
          <p><strong>${t('terms.accordion_3.p_5')}</strong></p>
          <p><strong>${t('terms.accordion_3.p_6')}</strong></p>`,
      active: false,
      id: 2,
    },
    {
      title: t('terms.accordion_4.title'),
      description: `<p><strong>${t('terms.accordion_4.title')}:</strong> ${t('terms.accordion_4.p_1')}</p>`,
      active: false,
      id: 3,
    },
    {
      title: t('terms.accordion_5.title'),
      description: `<p><strong>${t('terms.accordion_5.title')}:</strong> ${t('terms.accordion_5.p_1')}</p>`,
      active: false,
      id: 4,
    },
    {
      title: t('terms.accordion_6.title'),
      description: `<p><strong>${t('terms.accordion_6.title')}:</strong> ${t('terms.accordion_6.p_1')}</p>
          <p>${t('terms.accordion_6.p_2')}</p>
          <ol>
            <li>${t('terms.accordion_6.li_1')}</li>
            <li>${t('terms.accordion_6.li_2')}</li>
            <li>${t('terms.accordion_6.li_3')}</li>
            <li>${t('terms.accordion_6.li_4')}</li>
            <li>${t('terms.accordion_6.li_5')}</li>
            <li>${t('terms.accordion_6.li_6')}</li>
            <li>${t('terms.accordion_6.li_7')}</li>
            <li>${t('terms.accordion_6.li_8')}</li>
            <li>${t('terms.accordion_6.li_9')}</li>
            <li>${t('terms.accordion_6.li_10')}</li>
            <li>${t('terms.accordion_6.li_11')}</li>
            <li>${t('terms.accordion_6.li_12')}</li>
            <li>${t('terms.accordion_6.li_13')}</li>
            <li>${t('terms.accordion_6.li_14')}</li>
            <li>${t('terms.accordion_6.li_15')}</li>
            <li>${t('terms.accordion_6.li_16')}</li>
          </ol>
          <p>${t('terms.accordion_6.p_3')}</p>
          <ol><li><strong>${t('terms.accordion_6.li_17')}</strong>
          <p>${t('terms.accordion_6.p_4')}</p>
          <p>${t('terms.accordion_6.p_5')}</p>
          <p>${t('terms.accordion_6.p_6')}</p>
          <p>${t('terms.accordion_6.p_7')}</p>
          <p>${t('terms.accordion_6.p_8')}</p>
          <p>${t('terms.accordion_6.p_9')}</p>
          <p>${t('terms.accordion_6.p_10')}</p>
          <p>${t('terms.accordion_6.p_11')}</p>
          </li>
          <li><p><strong>${t('terms.accordion_6.li_18')}</strong></p>
          <p>${t('terms.accordion_6.p_12')}</p>
          <p>${t('terms.accordion_6.p_13')}</p>
          <p>${t('terms.accordion_6.p_14')}</p></li></ol>`,
      active: false,
      id: 5,
    },
    {
      title: t('terms.accordion_7.title'),
      description: `<p><strong>${t('terms.accordion_7.title')}:</strong> ${t('terms.accordion_7.p_1')}</p>`,
      active: false,
      id: 6,
    },
    {
      title: t('terms.accordion_8.title'),
      description: `<p><strong>${t('terms.accordion_8.title')}:</strong> ${t('terms.accordion_8.p_1')}</p>`,
      active: false,
      id: 7,
    },
    {
      title: t('terms.accordion_9.title'),
      description: `<p><strong>${t('terms.accordion_9.title')}:</strong> ${t('terms.accordion_9.p_1')}</p>
        <p>${t('terms.accordion_9.p_2')}</p>`,
      active: false,
      id: 8,
    },
    {
      title: t('terms.accordion_10.title'),
      description: `<p><strong>${t('terms.accordion_10.title')}:</strong> ${t('terms.accordion_10.p_1')}</p>
        <p>${t('terms.accordion_10.p_2')}</p>
        <p><ol>
        <li${t('terms.accordion_10.li_1')}li>
        <li>${t('terms.accordion_10.li_2')}</li>
        <li>${t('terms.accordion_10.li_3')}</li>
        <li>${t('terms.accordion_10.li_4')}</li>
        <li>${t('terms.accordion_10.li_5')}</li>
        </ol></p>
        <p>${t('terms.accordion_10.p_3')}</p>
        <p>${t('terms.accordion_10.p_4')}</p>
        <p>${t('terms.accordion_10.p_5')}</p>
        <p>${t('terms.accordion_10.p_6')}</p>
        <p>${t('terms.accordion_10.p_7')}</p>`,
      active: false,
      id: 9,
    },
    {
      title: t('terms.accordion_11.title'),
      description: `<p><strong>${t('terms.accordion_11.title')}:</strong> ${t('terms.accordion_11.p_1')}</p>
        <p>${t('terms.accordion_11.p_2')}</p>
        <p>${t('terms.accordion_11.p_3')}</p>
        <p>${t('terms.accordion_11.p_4')}</p>
        <p>${t('terms.accordion_11.p_5')}</p>`,
      active: false,
      id: 10,
    },
    {
      title: t('terms.accordion_12.title'),
      description: `<p><strong>${t('terms.accordion_12.title')}:</strong> ${t('terms.accordion_12.p_1')}</p>`,
      active: false,
      id: 11,
    },
    {
      title: t('terms.accordion_13.title'),
      description: `<p><strong>${t('terms.accordion_13.title')}:</strong> ${t('terms.accordion_13.p_1')}</p>`,
      active: false,
      id: 12,
    },
    {
      title: t('terms.accordion_14.title'),
      description: `<p><strong>${t('terms.accordion_14.title')}:</strong> ${t('terms.accordion_14.p_1')}</p>
        <p>${t('terms.accordion_14.p_2')}</p>`,
      active: false,
      id: 13,
    },
    {
      title: t('terms.accordion_15.title'),
      description: `<p><strong>${t('terms.accordion_15.subtitle_1')}</strong> ${t('terms.accordion_15.p_1')}</p>
        <p><strong>${t('terms.accordion_15.subtitle_2')}:</strong> ${t('terms.accordion_15.p_2')}</p>
        <p><strong>${t('terms.accordion_15.subtitle_3')}:</strong> ${t('terms.accordion_15.p_3')}</p>
        <p>${t('terms.accordion_15.p_4')}</p>
        <p><strong>${t('terms.accordion_15.subtitle_4')}:</strong> ${t('terms.accordion_15.p_5')}</p>
        <p><strong>${t('terms.accordion_15.subtitle_5')}:</strong> ${t('terms.accordion_15.p_6')}</p>
        <p>${t('terms.accordion_15.p_7')}</p>
        <p>${t('terms.accordion_15.p_8')}</p>
        <p>${t('terms.accordion_15.p_9')}</p>
        <p>${t('terms.accordion_15.p_10')}</p>
        <p><strong>${t('terms.accordion_15.subtitle_6')}:</strong> ${t('terms.accordion_15.p_11')}</p>
        <p>${t('terms.accordion_15.p_12')}</p>
        <p>${t('terms.accordion_15.p_13')}</p>
        <p>${t('terms.accordion_15.p_14')}</p>
        <p>${t('terms.accordion_15.p_15')}</p>
        <p><strong>${t('terms.accordion_15.subtitle_7')}:</strong> ${t('terms.accordion_15.p_16')}</p>
        <p>${t('terms.accordion_15.p_17')}</p>
        <p>${t('terms.accordion_15.p_18')}</p>
        <p><strong>${t('terms.accordion_15.subtitle_8')}</strong></p>
        <p><strong>${t('terms.accordion_15.p_19')}</strong></p>
        <p><strong>${t('terms.accordion_15.subtitle_9')}:</strong> ${t('terms.accordion_15.p_20')}</p>
        <p><strong>${t('terms.accordion_15.subtitle_10')}:</strong> ${t('terms.accordion_15.p_21')}</p>
        <p>${t('terms.accordion_15.p_22')}</p>
        <p><strong>${t('terms.accordion_15.subtitle_11')}</strong></p>
        <p><strong>${t('terms.accordion_15.subtitle_12')}</strong></p>
        <p><strong>${t('terms.accordion_15.subtitle_13')}</strong></p>
        <p>${t('terms.accordion_15.p_23')}</p>
        <p><strong>${t('terms.accordion_15.subtitle_14')}</strong></p>`,
      active: false,
      id: 14,
    }
  ];

  const [accordeonTerminosServicios, setAccordeonTerminosServicios] =
  useState<TabAccordeon[]>(termsOptions);

  return (
    <>
      <div className="terminosServicioContainer">
        <div className="terminosServicioContainer_top">
          <div className="col-12 col-lg-7 terminosServicio-information__principal-title">
            <span>{t('terms.title')}</span>
            <p >{t('terms.description')}</p>
          </div>
          <div className="col-12 col-lg-5">
            <img src={imgTerServ} alt="" />
          </div>
        </div>
      </div>
      <div className="terminosServicioContainer">
        <div id="accordion">
          {accordeonTerminosServicios.map((acorItem) => (

            <div
              className={`card${acorItem.active ? "" : "-false"}`}
              key={0}
              onClick={() => handleOpenCloseTab(acorItem)}
            >
              <div
                className={`card-header${acorItem.active ? "" : "-false"}`}
                style={{ width: "100%" }}
                id='1'
              >
                <h5 className="mb-0">
                  <span
                    className="btn"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {acorItem.title}
                  </span>
                </h5>
              </div>

              <div
                className={`collapse${acorItem.active ? " show" : ""}`}
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div
                  className="card-body"
                  dangerouslySetInnerHTML={{ __html: acorItem.description }}
                ></div>
              </div>
            </div>
         ))}
        </div>
      </div>
    </>
  );
};

export default TerminosServicioPage;
