import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostApiUserPersonalInformation } from '../../api/interconectar-api';
import { PersonalInformationCommand } from '../../api';

export function useProfileAccountActions(): {
    personalInformationPost: (personalInformation: PersonalInformationCommand) => void;
    isLoading: boolean
} {
    
  const { mutate: postUserPersonalInformation, isLoading } = usePostApiUserPersonalInformation();
  const navigate = useNavigate();
  const personalInformationPost = useCallback(
    (personalInformation: PersonalInformationCommand): void => {
        postUserPersonalInformation(
        {
          data: personalInformation,
        },
        {
          onSuccess(data, variables, context) {
            navigate("/information", { state: { variation: "register_ok" } });
          },
          onError(error, variables, context) {
              navigate("/information", { state: { variation: "error" } });
          },
        }
      )
    },
    [postUserPersonalInformation, navigate]
  );

  return {
    personalInformationPost,
    isLoading
  };
}