import React, { useState } from "react";
import rPasswordImage from "../../assets/images/RPassword.png";
import { useTranslation } from "react-i18next";
import './RecoverPassword.css'
import { useResetRecoverPasswordActions } from "./resetPassword.logic";
import CircularLoading from "../../shared/CircularLoading/CircularLoading";


const RecoverPassword: React.FC = () => {
    const {t} = useTranslation()
    const { recoverPassword, isLoadingRecover } = useResetRecoverPasswordActions();

    const [error, setError] = useState("");
    const [emailAlias, setEmailAlias] = useState("");
    
    const handleButtonClick = () => {
        if (emailAlias === "")
        {
            setError(t("login.recover.error.empty"))
        }
        else
        {
            recoverPassword(emailAlias);
        }
    };

    if (isLoadingRecover)
    {
        return (<CircularLoading/>);
    }

    return (
    <div className="rPassword-container">
        <img
            className="rPassword-image"
            src={rPasswordImage}
            alt={t('login.recover.title')}
        />
        <h2 className="rPassword-title">{t('login.recover.title')}</h2>
        <p className="rPassword-description">{t('login.recover.description')}</p>
        <div className="email-input-container">
        <input
          placeholder={t('login.recover.email.placeholder')}
          className="txt-pass-rpass"
          type="text"
          value={emailAlias}
          onChange={e => setEmailAlias(e.target.value)}
        />
      </div>
      {error && <div className="rPassword-description">{error}</div>}
      <button className="rPassword-btn rPassword-btn-text" onClick={handleButtonClick}>{t('login.recover.button')}</button>
    </div>);
}


export default RecoverPassword;