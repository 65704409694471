import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import rPasswordImage from "../../assets/images/RPassword.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './RPassword.css';
import { useResetRecoverPasswordActions } from "../RecoverPassword/resetPassword.logic";
import CircularLoading from "../../shared/CircularLoading/CircularLoading";

interface RPasswordProps {
  token?: string;
}

const RPassword: React.FC<RPasswordProps> = ({ token }) => {
  const {t} = useTranslation()
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { resetPassword, isLoadingResetPassword } = useResetRecoverPasswordActions();

  const toggleShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleButtonClick = () => {
    if ((password === "" || confirmPassword === "") || (password !== confirmPassword))
    {
      setError(t("login.reset.error.empty"))
    }
    else
    {
      resetPassword(password, confirmPassword, token);
    }
  };

  if (isLoadingResetPassword)
  {
    return (<CircularLoading/>);
  }

  return (
    <div className="rPassword-container">
      <img
        className="rPassword-image"
        src={rPasswordImage}
        alt="Login"
      />
      <h2 className="rPassword-title">{t('login.reset.title')}</h2>
      <div className="password-input-container">
        <input
          placeholder={t('login.reset.password.placeholder')}
          className="txt-pass-rpass"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
          onClick={toggleShowPassword}
          className="toggle-password-icon"
        />
      </div>
      <div className="password-input-container">
        <input
          placeholder={t('login.reset.confirm_password.placeholder')}
          className="txt-pass-rpass"
          type={showPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
        />
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
          onClick={toggleShowPassword}
          className="toggle-password-icon"
        />
      </div>
      {error && <div className="rPassword-description">{error}</div>}
      <button className="rPassword-btn rPassword-btn-text" onClick={handleButtonClick}>{t('login.reset.button')}</button>
    </div>
  );
};

export default RPassword;
