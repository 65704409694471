import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import "./politicasprivacidad.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import imgTerServ from "../../assets/images/PoliticasLogo.png";

const PoliticasPrivacidadPage: React.FC = () => {
  const {t} = useTranslation()
  interface TabAccordeon {
    title: string;
    description: string;
    id: number;
    active: boolean;
  }

  const textOptions = [
    {
      title: t('policy.accordion_1.title'),
      description: t('policy.accordion_1.p_1'),
      active: false,
      id: 0,
    },
    {
      title: t('policy.accordion_2.title'),
      description: `
        <p>${t('policy.accordion_2.p_1')}</p>
        <p>${t('policy.accordion_2.p_2')}</p>
        <p>${t('policy.accordion_2.p_3')}</p>
        <p>${t('policy.accordion_2.p_4')}</p>
        <p>${t('policy.accordion_2.p_5')}</p>
    `,
      active: false,
      id: 1,
    },
    {
      title: t('policy.accordion_3.title'),
      description: `
        <strong>${t('policy.accordion_3.p_1')}</strong>
        <p>${t('policy.accordion_3.p_2')}</p>
        <strong>${t('policy.accordion_3.p_3')}</strong>:
        <p>${t('policy.accordion_3.p_4')}</p>
        <p>${t('policy.accordion_3.p_5')}</p>
        <p>${t('policy.accordion_3.p_6')}</p>
        <p>${t('policy.accordion_3.p_7')}</p>
      `,
      active: false,
      id: 2,
    },
    {
      title: t('policy.accordion_4.title'),
      description: `
        <p>${t('policy.accordion_4.p_1')}</p>
        <p>${t('policy.accordion_4.p_2')}</p>`,
      active: false,
      id: 3,
    },
    {
      title: t('policy.accordion_5.title'),
      description: `
        <p><strong>${t('policy.accordion_5.p_1')}:</strong> ${t('policy.accordion_5.p_2')}</p>
        <p><strong>${t('policy.accordion_5.p_3')}:</strong> ${t('policy.accordion_5.p_4')}</p>
        <p><strong>${t('policy.accordion_5.p_5')}:</strong> ${t('policy.accordion_5.p_6')}</p>
        <p><strong>${t('policy.accordion_5.p_7')}:</strong> ${t('policy.accordion_5.p_8')}</p>`,
      active: false,
      id: 4,
    },
    {
      title: t('policy.accordion_6.title'),
      description: `<p>${t('policy.accordion_6.p_1')}</p>
      <p>${t('policy.accordion_6.p_2')}</p>
      <p>${t('policy.accordion_6.p_3')}</p>`,
      active: false,
      id: 5,
    },
    {
      title: t('policy.accordion_7.title'),
      description: t('policy.accordion_7.p_1'),
      active: false,
      id: 6,
    },
    {
      title: t('policy.accordion_8.title'),
      description: t('policy.accordion_8.p_1'),
      active: false,
      id: 7,
    },
    {
      title: t('policy.accordion_9.title'),
      description: t('policy.accordion_9.p_1'),
      active: false,
      id: 8,
    },
    {
      title: t('policy.accordion_10.title'),
      description: t('policy.accordion_10.p_1'),
      active: false,
      id: 9,
    },
    {
      title: t('policy.accordion_11.title'),
      description: t('policy.accordion_11.p_1'),
      active: false,
      id: 10,
    },
    {
      title: t('policy.accordion_12.title'),
      description: t('policy.accordion_12.p_1'),
      active: false,
      id: 11,
    },
  ];

  const [accordeonTerminosServicios, setAccordeonTerminosServicios] =
    useState<TabAccordeon[]>(textOptions);

  function handleOpenCloseTab(item: TabAccordeon) {
    if (item.active) {
      setAccordeonTerminosServicios(
        accordeonTerminosServicios.map((el) =>
          el.id === item.id ? { ...el, active: false } : el
        )
      );
    } else {
      setAccordeonTerminosServicios(
        accordeonTerminosServicios.map((el) =>
          el.id === item.id ? { ...el, active: true } : el
        )
      );
    }
  }

  return (
    <>
      <div className="terminosServicioContainer">
        <div className="terminosServicioContainer_top">
          <div className="col-12 col-lg-7 terminosServicio-information__principal-title">
            <span>{t('policy.title')}</span>
            <p >{t('policy.description')}</p>
          </div>
          <div className="col-12 col-lg-5">
            <img src={imgTerServ} alt="" />
          </div>
        </div>
      </div>
      <div className="terminosServicioContainer">
        <div id="accordion acordeonTabs">
          {accordeonTerminosServicios.map((acorItem) => (
            <div
              className={`card${acorItem.active ? "" : "-false"}`}
              key={acorItem.id}
              onClick={() => handleOpenCloseTab(acorItem)}
            >
              <div className="row">
                <div className="col-12   ">
                  <div
                    className={`card-header${acorItem.active ? "" : "-false"}`}
                    style={{ width: "100%" }}
                    id={acorItem.id.toString()}
                  >
                    <h5 className="mb-0">
                      <span
                        className="btn"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {acorItem.title}
                      </span>
                    </h5>
                  </div>
                  <div
                    id="collapseOne"
                    className={`collapse${acorItem.active ? " show" : ""}`}
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div
                      className="card-body"
                      dangerouslySetInnerHTML={{ __html: acorItem.description }}
                    ></div>
                  </div>
                </div>
                <div className=" terminosServicio-information__arrow">
                  <FontAwesomeIcon
                    icon={acorItem.active ? faChevronUp : faChevronDown}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PoliticasPrivacidadPage;
